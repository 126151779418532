import { FieldsContainerProps } from "./types";
import Tag from "../../../components/Tag";
import Box from "../../../components/Box";

function FieldsContainer({ fields, onTagClicked }: FieldsContainerProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        "&:last-child": {
          paddingBottom: "0",
        },
      }}
    >
      {fields.map(field => (
        <Box m="10px" key={field.id}>
          <Tag {...field} onClick={() => onTagClicked(field.id)} />
        </Box>
      ))}
    </Box>
  );
}

export default FieldsContainer;
