import styled from "styled-components";
import { TagProps } from "./types";

type TextProps = {
  lines: number;
  sizeMultiplier: number;
  isSelected?: boolean;
  length: number;
};

const LINES_TO_FONT = [16, 15, 12];

const getFontSize = (props: TextProps) => {
  const defSize = LINES_TO_FONT[props.lines - 1] * props.sizeMultiplier;
  return props.length > 15 && props.isSelected ? defSize - 2 : defSize;
};

export const Text = styled.div<TextProps>`
  text-align: center;
  color: var(--radar-tag-text-color);
  font-size: ${props => getFontSize(props)}px;
  font-family: "Roboto";
  font-weight: 500;
  width: 100%;
  line-height: ${props => 15 * props.sizeMultiplier}px;
  ${props =>
    props.length > 15 && props.isSelected
      ? "width: calc(100% - 26px); line-height: 16px;"
      : ""}
`;

export const TextContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const SvgContainer = styled.svg<Partial<TagProps>>`
  cursor: ${props => (props.isTagClickEnabled ? "pointer" : "default")};
`;

export const CurrentTagGContainer = styled.g<Partial<TagProps>>`
  transform: translate(calc(50% - 125px), calc(50% - 13px));
`;
