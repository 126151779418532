import styled from "styled-components";

export const StyledDownload = styled.div`
  display: flex;
  position: relative;
  gap: 20px;
  max-width: 600px;
  width: 100%;
  border: 2px solid rgb(0, 23, 31);
  padding: 22px;
  margin-bottom: 20px;

  svg {
    max-width: 37px;
  }

  button {
    position: absolute;
    right: 15px;
  }
`;

export const StyledLoaderBackDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--white-rgb), 0.67);

  & > span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
