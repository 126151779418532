import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  width: auto;
  position: relative;
`;

export const SvgContainer = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const RadarLogo = styled.img`
  position: absolute;
  width: 20%;
  right: 4%;
  top: 2%;
`;
