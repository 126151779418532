import styled from "styled-components";
import Box from "../../components/Box";

export const RadarWizardWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const RadarWrapper = styled.div`
  width: 60%;
  height: 100%;
`;

export const WizardWrapper = styled.div`
  overflow: hidden;
`;

export const StepWrapper = styled(Box)`
  position: absolute;
  z-index: 1;
  width: 40%;
  right: 0;
  height: 100%;
  min-height: 768px;
  background: var(--white);
  box-shadow: var(--box-shadow);
  overflow: auto;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;

  @media (min-width: 1366px) {
    padding: 30px 40px;
  }
`;

export const ButtonsContainer = styled(Box)`
  width: 100%;
  margin: 20px 0 20px;
  padding: 20px 0 0;
  border-top: 1px solid var(--grey);
  display: flex;
  justify-content: center;
  gap: 10px;
`;
