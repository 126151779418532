import { getCustomizationParams } from "./index";
import qs from "qs";
import { useNavigate, NavigateFunction, NavigateOptions } from "react-router";
import { useCallback } from "react";
import { GenericObject } from "../@types";

const initialCustomizationParams = getCustomizationParams();

export function generatePathWithParams(
  to: string,
  customizationParams: GenericObject
) {
  const [toPath, toParams] = to.split("?");
  const toParamsParsed = qs.parse(toParams, { ignoreQueryPrefix: true });
  const paramsStr = qs.stringify({ ...toParamsParsed, ...customizationParams });

  return `${toPath}${paramsStr ? `?${paramsStr}` : ""}`;
}

export function useNavigateWithState() {
  const navigate = useNavigate();

  const navigateWithState = (to: string, options?: NavigateOptions) => {
    return navigate(
      generatePathWithParams(to, initialCustomizationParams),
      options
    );
  };

  return useCallback(navigateWithState, [navigate]) as NavigateFunction;
}
