import { PdfIcon, XlsIcon } from "./icons";

export const ICONS = {
  pdf: PdfIcon,
  xls: XlsIcon,
};

export const getSubtitle = (key: string, t: any) => {
  const subTitles: any = {
    pdf: t("wizard.summary.downloadPDF", "CSP RADAR PDF"),
    xls: t("wizard.summary.downloadXLS", "CSP SCORECARD"),
  };

  return subTitles[key];
};
