import styled from "styled-components";

type TagWrapperProps = {
  backgroundColor: string;
  borderColor: string;
  pointer?: boolean;
  onClick?: () => void;
};

export const TagWrapper = styled.div<TagWrapperProps>`
  width: 150px;
  height: 29.21px;
  background-color: rgb(var(--white-rgb));
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 2px solid ${props => props.borderColor};
  background-color: ${props => props.backgroundColor};
  padding: 0px 3px;

  span {
    font-weight: 500;
  }

  &:hover {
    cursor: ${p => (p.onClick || p.pointer) && "pointer"};
  }
`;
