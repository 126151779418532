import styled from "styled-components";

export const UnderlinedButton = styled.u`
  cursor: pointer;
`;

export const FunkLogo = styled.img`
  width: 60px;
  height: 100%;
  cursor: pointer;
`;

export const FunkText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
`;

export const FunkDescription = styled.span`
  font-size: 14px;
  white-space: normal;
`;

export const FunkFoundationWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 65px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;

  img,
  u,
  a {
    padding: 0 5px;
  }

  @media (max-width: 1360px) {
    padding: 55px 30px;
    span {
      display: none;
    }
  }
`;
