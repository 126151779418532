import React from "react";
import Box from "../Box";
import { LockIcon } from "../icons";

export const LockedInfo: React.FC = props => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LockIcon />
      <Box sx={{ paddingLeft: "10px" }}>{props.children}</Box>
    </Box>
  );
};
