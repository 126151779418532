import { CustomizationVariables, SelectionMap } from "../@types";
import { DtoKpi } from "../routes/RadarWizard/types";
import {
  AppState,
  selectedFieldsVar,
  selectedHorizonVar,
  selectedKpisVar,
  selectedPresetVar,
  showOnlyImportantConnectionsVar,
} from "../apollo/state";
import qs from "qs";
import { parsePosition } from "../components/Radar/positioning-utils";

export function ceilValueByScale(value: number, scale: number) {
  return Math.ceil(value / scale) * scale;
}

export function getFieldOpacity(weightValue: number) {
  return ceilValueByScale(weightValue, 0.2);
}

export function isTouchDevice() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
}

export const isTopQuadrant = (quadrant: number) =>
  quadrant === 1 || quadrant === 2;

export const isLeftQuadrant = (quadrant: number, position: string | null) => {
  if (quadrant === 0) {
    return parsePosition(position!).x < 40;
  }

  return quadrant === 1 || quadrant === 3;
};

export function getSelectedElementsCount(map: SelectionMap) {
  return Object.values(map).filter(Boolean).length;
}

export function getCurrFieldKPIsCount(
  currFieldKPIs: DtoKpi[],
  allSelectedKPIs: SelectionMap
) {
  return currFieldKPIs.filter(kpi => !!allSelectedKPIs[kpi.id]).length;
}

export function getAppState(): AppState {
  return {
    ...getCustomizationParams(),
    selectedPreset: selectedPresetVar(),
    selectedFields: selectedFieldsVar(),
    selectedKpis: selectedKpisVar(),
    selectedHorizon: selectedHorizonVar(),
    showOnlyImportantConnections: showOnlyImportantConnectionsVar(),
  };
}

export function setAppState({
  selectedHorizon,
  selectedKpis,
  selectedPreset,
  selectedFields,
  showOnlyImportantConnections,
}: AppState) {
  selectedPresetVar(selectedPreset);
  selectedFieldsVar(selectedFields);
  selectedKpisVar(selectedKpis);
  selectedHorizonVar(selectedHorizon);
  showOnlyImportantConnectionsVar(showOnlyImportantConnections);
}

export function getCustomizationParams() {
  const { sheetId = undefined, localeId = undefined } = qs.parse(
    document.location.search,
    { ignoreQueryPrefix: true }
  ) as CustomizationVariables;

  const retValue: any = {};

  if (sheetId) {
    retValue.sheetId = sheetId;
  }

  if (localeId) {
    retValue.localeId = localeId;
  }
  return retValue;
}
