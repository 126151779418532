import {
  createContext,
  forwardRef,
  useContext,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import { Ref } from "../../@types";
import Box, { BoxProps } from "../Box";

type Context = {
  open: boolean;
  setOpen: (open: boolean) => void;
};
type AccordionProps = {
  defaultOpen?: boolean;
} & BoxProps;

const StyledAccordionSummary = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledIconWrapper = styled<any>(Box)`
  font-size: 12px;
  cursor: pointer;
  transform: ${s => s.open && "rotate(180deg)"};
  height: 11px;
`;

const AccordionContext = createContext<Context>({
  open: false,
  setOpen: () => undefined,
});

export const Accordion = forwardRef<Ref, AccordionProps>(
  ({ defaultOpen = false, ...rest }, ref) => {
    const [open, setOpen] = useState(defaultOpen);
    const value = useMemo(() => ({ open, setOpen }), [open]);

    return (
      <AccordionContext.Provider value={value}>
        <Box ref={ref} {...rest} />
      </AccordionContext.Provider>
    );
  }
);

export const AccordionSummary = forwardRef<Ref, BoxProps>(
  ({ children, ...rest }, ref) => {
    const { open, setOpen } = useContext(AccordionContext);

    return (
      <StyledAccordionSummary
        ref={ref}
        onClick={() => {
          setOpen(!open);
        }}
        {...rest}
      >
        {children}
        <StyledIconWrapper as="span" open={open}>
          &#9660;
        </StyledIconWrapper>
      </StyledAccordionSummary>
    );
  }
);

export const AccordionDetail = forwardRef<Ref, BoxProps>((props, ref) => {
  const { open } = useContext(AccordionContext);

  if (!open) return null;

  return <Box ref={ref} {...props} />;
});
