import { calculateItemPosition, parsePosition } from "./positioning-utils";
import { TagProps } from "./components/Tag/types";
import { FieldErrors, HydratedField } from "../../routes/RadarWizard/types";
import { getFieldOpacity } from "../../utils";
import { Position } from "./types";

export const createTag = (
  field: HydratedField,
  { x, y }: Position,
  fieldErrors?: FieldErrors,
  currentField?: HydratedField
) => {
  const warnings = [];

  if (fieldErrors) {
    if (fieldErrors.importantFieldNotSelected.includes(field.id)) {
      warnings.push("wizard.preview.importantFieldNotSelected");
    }

    if (fieldErrors.importantConnectionNotSelected.includes(field.id)) {
      warnings.push("wizard.preview.importantConnectionNotSelected");
    }
  }

  return {
    field,
    x: `${x}%`,
    y: `${y}%`,
    lines: Math.ceil(field.name.length / 22),
    bgOpacity: getFieldOpacity(field.weight),
    isCurrent: currentField && currentField.id === field.id,
    warnings,
  };
};

export const createTags = (
  fields: HydratedField[],
  fieldErrors?: FieldErrors,
  currentField?: HydratedField
) => {
  return fields.map<TagProps>(field => {
    const position: Position =
      field.quadrant === 0
        ? parsePosition(field.position)
        : calculateItemPosition(
            parseInt(field.position, 10) - 1,
            field.quadrant
          );

    return createTag(field, position, fieldErrors, currentField);
  });
};
