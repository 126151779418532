import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  selectedFieldsVar,
  showOnlyImportantConnectionsVar,
  selectedKpisVar,
} from "../../../apollo/state";
import {
  Accordion,
  AccordionDetail,
  AccordionSummary,
} from "../../../components/Accordion";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import SectionTitle from "../../../components/SectionTitle";
import Tag from "../../../components/Tag";
import Typography from "../../../components/Typography";
import Guide from "../Guide";
import { StepWrapper, ButtonsContainer } from "../styled";
import { STEP_URLS } from "../../../constants/routing";
import { FieldErrors, HydratedField } from "../types";
import { WarningIcon } from "../../../components/icons/index";
import Checkbox from "../../../components/Checkbox";
import { useNavigateWithState } from "../../../utils/useNavigateWithState";

const StyledKpi = styled.div`
  border: 1px solid #d0d0d0;
  padding: 10px 20px;
  border-radius: 9999px;
  cursor: pointer;
`;

type Props = {
  fieldsMap: Record<string, HydratedField>;
  fieldErrors: FieldErrors;
};

function Preview({ fieldsMap, fieldErrors }: Props) {
  const selectedKPIs = useReactiveVar(selectedKpisVar);
  const selectedFields = useReactiveVar(selectedFieldsVar);
  const showOnlyImportantConnections = useReactiveVar(
    showOnlyImportantConnectionsVar
  );
  const { t } = useTranslation();
  const navigate = useNavigateWithState();

  function renderWarning(msg: string) {
    return (
      <Typography
        size="13px"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
          marginTop: "5px",
        }}
      >
        <WarningIcon />
        {msg}
      </Typography>
    );
  }

  return (
    <StepWrapper
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <SectionTitle>{t("wizard.preview.preview", `Preview`)}</SectionTitle>
      <Box sx={{ overflow: "auto", height: "100%" }}>
        {Object.keys(selectedFields).map(fId => {
          const field = fieldsMap[fId];
          return (
            <Accordion key={`f${fId}`} m="0 0 10px" defaultOpen>
              <AccordionSummary>
                <Tag pointer {...field} />
              </AccordionSummary>
              <AccordionDetail>
                <Typography
                  m="20px 0"
                  dangerouslySetInnerHTML={{ __html: field.description }}
                />
                {field.KPIs.map((kpi: any) => {
                  if (!selectedKPIs[kpi.id]) {
                    return null;
                  }
                  return (
                    <Accordion key={`kp${kpi.id}`} m="0 0 10px" defaultOpen>
                      <AccordionSummary>
                        <StyledKpi>{kpi.shortName}</StyledKpi>
                      </AccordionSummary>
                      <AccordionDetail m="10px 0 20px 20px">
                        <Typography>{kpi.name}</Typography>
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: kpi.description,
                          }}
                        />
                      </AccordionDetail>
                    </Accordion>
                  );
                })}
              </AccordionDetail>
            </Accordion>
          );
        })}
      </Box>
      <ButtonsContainer sx={{ flexWrap: "wrap" }}>
        <Box
          as="label"
          sx={{ cursor: "pointer", width: "100%", textAlign: "center" }}
        >
          <Checkbox
            checked={!!showOnlyImportantConnections}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              showOnlyImportantConnectionsVar(e.currentTarget.checked);
            }}
          />
          <Typography as="span" size="13px">
            {t(
              "wizard.preview.importantConnections",
              `Show only important connections`
            )}
          </Typography>
        </Box>
        <Button
          onClick={() => {
            navigate(STEP_URLS.pickFields);
          }}
        >
          {t("wizard.preview.addFields", `Add fields`)}
        </Button>
        <Button
          onClick={() => {
            navigate(STEP_URLS.summary);
          }}
        >
          {t("wizard.preview.ready", `Ready`)}
        </Button>
      </ButtonsContainer>
      <Guide />
      {fieldErrors.importantFieldNotSelected.length > 0 &&
        renderWarning(
          t(
            "wizard.preview.importantFieldNotSelected",
            `Important field is not selected`
          )
        )}
      {fieldErrors.importantConnectionNotSelected.length > 0 &&
        renderWarning(
          t(
            "wizard.preview.importantConnectionNotSelected",
            `Field with important connection is not selected`
          )
        )}
    </StepWrapper>
  );
}

export default Preview;
