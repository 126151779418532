import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import RadarWizard from "./RadarWizard";
import RadarExport from "./RadarExport";
import IntroWizard from "./IntroWizard";
import { STEP_URLS } from "../constants";
import { NavigateWithState } from "../components/NavigateWithState";

function LayoutRouter() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path={STEP_URLS.intro} element={<IntroWizard />} />
        <Route path="/" element={<NavigateWithState to={STEP_URLS.start} />} />
        <Route path="/export/pdf" element={<RadarExport mode="pdf" />} />
        <Route path="/export/image" element={<RadarExport mode="image" />} />
        <Route path="*" element={<RadarWizard />} />
      </Routes>
    </Router>
  );
}

export default LayoutRouter;
