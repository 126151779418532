import { gql } from "@apollo/client";

export const GET_INTRO_DATA = gql`
  query getIntroData($sheetId: String, $localeId: String) {
    intros(sheetId: $sheetId, localeId: $localeId) {
      number
      leftImage
      introContent
      introDetailContent
    }
  }
`;
