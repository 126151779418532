import ReactTooltip, { TooltipProps } from "react-tooltip";
import styled from "styled-components";

const StyledTooltip = styled(ReactTooltip)`
  && {
    border-radius: 5px;
    box-shadow: 0 1px 15px var(--grey);
    padding: 0;

    &.place-top {
      margin-top: -5px;
    }
    &.show {
      opacity: 1;
    }
  }
`;

function Tooltip(props: TooltipProps) {
  return <StyledTooltip {...props} />;
}

export default Tooltip;
