import {
  Weight,
  WeightByFieldMap,
  DtoKpi,
  DtoMatrix,
  HydratedField,
  FieldsConnectionMap,
  HydratedConnectedField,
  AvailablePresets,
} from "./types";
import { keyBy, groupBy, orderBy, take, sum, uniq } from "lodash-es";
import { GenericObject, SelectionMap } from "../../@types";
import { matchPath, useLocation } from "react-router-dom";
import { STEP_URLS } from "../../constants";
import {
  selectedKpisVar,
  selectedFieldsVar,
  showOnlyImportantConnectionsVar,
  selectedPresetVar,
} from "../../apollo/state";
import { FIELD_ID_PARAM } from "../../constants/routing";
import { useNavigateWithState } from "../../utils/useNavigateWithState";

export const TOP_FIELDS_COUNT = 5;

export function getAvailablePresets(weights: Weight[]) {
  const [standard, ...custom] = weights[0].presets;
  return {
    standard,
    custom,
  };
}

export function getWeightByFieldMap(weights: Weight[]) {
  return weights.reduce<WeightByFieldMap>((acc, weight) => {
    acc[weight.field] = keyBy(weight.presets, "key");
    return acc;
  }, {});
}

export function getFieldsMap<T>(fields: T[]) {
  return keyBy(fields, "id");
}

export function groupKpis(kpis: DtoKpi[]) {
  return groupBy(kpis, "field");
}

export function processMatrix<TValueType>(matrix: DtoMatrix<TValueType>) {
  function getIndex(i: number) {
    return (i + 1).toString();
  }

  return matrix.values.reduce<GenericObject>((acc, item, i) => {
    acc[getIndex(i)] = item.reduce<GenericObject>((subAcc, subItem, j) => {
      subAcc[getIndex(j)] = subItem;
      return subAcc;
    }, {});
    return acc;
  }, {});
}

export function someUrlsMatches(
  urls: string[],
  pathName: string,
  isNot: boolean
) {
  if (isNot) {
    return urls.every(pattern => {
      return !matchPath(pattern, pathName);
    });
  } else {
    return urls.some(pattern => {
      return !!matchPath(pattern, pathName);
    });
  }
}

export function useUrlMatching(urls: string[], isNot: boolean = false) {
  const location = useLocation();
  return someUrlsMatches(urls, location.pathname, isNot);
}

export function useTagClickEnabled() {
  return useUrlMatching([STEP_URLS.pickFields, STEP_URLS.pickKeysForField]);
}

export function useRadarTooltipEnabled() {
  return useUrlMatching([STEP_URLS.start, STEP_URLS.intro], true);
}

export function usePreviewEnabled() {
  return useUrlMatching([STEP_URLS.preview]);
}

export function useNavigateField() {
  const navigate = useNavigateWithState();

  return (fieldId: string) => {
    navigate(STEP_URLS.pickKeysForField.replace(FIELD_ID_PARAM, fieldId));
  };
}

export function useCurrentField(
  hydratedFieldsMap: Record<string, HydratedField>
) {
  const location = useLocation();
  const match = matchPath(STEP_URLS.pickKeysForField, location.pathname);

  if (match) {
    return hydratedFieldsMap[match.params.fieldId as string];
  }
}

export function getStaticTopConnections(
  matrix: GenericObject,
  connectionLabels: GenericObject
) {
  const keys = Object.keys(matrix);
  return keys.reduce<FieldsConnectionMap>((acc, key) => {
    const values = orderBy(
      Object.keys(matrix[key])
        .filter(innerKey => innerKey !== key)
        .map(innerKey => ({
          field: innerKey,
          value: matrix[key][innerKey],
          label: connectionLabels[key][innerKey],
        })),
      ["value"],
      ["desc"]
    );
    acc[key] = take(values, TOP_FIELDS_COUNT).map((item, index) => ({
      ...item,
      size: TOP_FIELDS_COUNT - index,
    }));
    return acc;
  }, {});
}

export function getManyToManyConnections(
  matrix: GenericObject,
  connectionLabels: GenericObject,
  selectedFields: SelectionMap,
  staticFieldsConnectionsMap: FieldsConnectionMap
) {
  const connectedFields: FieldsConnectionMap = {};
  const selectedFieldIds = Object.keys(selectedFields).filter(
    key => selectedFields[key]
  );
  const fieldsWithSum = selectedFieldIds.reduce<GenericObject>((acc, key) => {
    acc[key] = {
      sum: sum(Object.values(matrix[key])),
    };
    return acc;
  }, {});

  const totalSum = sum(selectedFieldIds.map(id => fieldsWithSum[id].sum));

  const fieldsCalculations = selectedFieldIds.reduce<GenericObject>(
    (acc, key) => {
      const field = acc[key];
      const weightFactor = field.sum / totalSum;
      const adjustedMatrix = Object.keys(matrix[key]).reduce<GenericObject>(
        (subAcc, subKey) => {
          subAcc[subKey] = matrix[key][subKey] * weightFactor;
          return subAcc;
        },
        {}
      );
      acc[key] = { ...field, weightFactor, adjustedMatrix };
      return acc;
    },
    fieldsWithSum
  );

  const adjustedMatrixArray = selectedFieldIds.reduce<any[]>((acc, key) => {
    const fieldMatrix = fieldsCalculations[key].adjustedMatrix;
    const currFieldConnections = Object.keys(fieldMatrix).map(subKey => {
      return {
        sourceFieldId: key,
        targetFieldId: subKey,
        value: fieldMatrix[subKey],
        label: connectionLabels[key][subKey],
      };
    });

    return [...acc, ...currFieldConnections];
  }, []);

  const topConnections = take(
    orderBy(adjustedMatrixArray, ["value"], ["desc"]),
    TOP_FIELDS_COUNT * selectedFieldIds.length
  );

  topConnections.forEach((tc, index) => {
    if (!connectedFields[tc.sourceFieldId]) {
      connectedFields[tc.sourceFieldId] = [];
    }
    connectedFields[tc.sourceFieldId].push({
      field: tc.targetFieldId,
      value: tc.value,
      label: tc.label,
      size: TOP_FIELDS_COUNT - Math.floor(index / selectedFieldIds.length),
    });
  });

  return { ...staticFieldsConnectionsMap, ...connectedFields };
}

export function getFieldErrors(
  isSelected: boolean,
  selectedFields: Record<string, boolean>,
  connectedFields: HydratedConnectedField[]
) {
  let importantConnectionNotSelected: string[] = [];

  if (isSelected) {
    importantConnectionNotSelected = connectedFields
      .filter(cn => cn.size === 5 && !selectedFields[cn.id])
      .map(cn => cn.id);
  }

  return { importantConnectionNotSelected };
}

export function resetSelectedData(availablePresets: AvailablePresets) {
  selectedPresetVar(availablePresets.standard.key);
  selectedKpisVar({});
  selectedFieldsVar({});
  showOnlyImportantConnectionsVar(false);
}

export function hasAllFieldTypeSelected(fields: HydratedField[]) {
  const uniqFieldTypes = uniq(fields.map(f => f.type.id));
  const selectedFields = fields.filter(f => f.isSelected);
  return uniqFieldTypes.every(fType =>
    selectedFields.find(sf => sf.type.id === fType)
  );
}
