import { ApolloProvider } from "@apollo/client";
import { Suspense } from "react";
import { Normalize } from "styled-normalize";
import { Toaster } from "react-hot-toast";
import { client } from "./apollo";
import { GlobalLoader } from "./components/Loader";
import Tooltip from "./components/Tooltip";
import { GlobalStyles } from "./GlobalStyles";
import LayoutRouter from "./routes";
import { ConfigurableStylesProvider } from "./context/ConfigurableStyles";
import { SettingsProvider } from "./context/Settings";

function App() {
  return (
    <>
      <Normalize />
      <Suspense fallback={<GlobalLoader />}>
        <ApolloProvider client={client}>
          <ConfigurableStylesProvider>
            <SettingsProvider>
              <GlobalStyles />
              <LayoutRouter />
            </SettingsProvider>
          </ConfigurableStylesProvider>
        </ApolloProvider>
      </Suspense>
      <Tooltip globalEventOff="click" delayShow={400} />
      <Toaster />
    </>
  );
}

export default App;
