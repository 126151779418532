import styled from "styled-components";
import { forwardRef, SelectHTMLAttributes } from "react";
import Box, { BoxProps } from "../Box";
import { Ref } from "../../@types";

type SelectProps = {
  disabled?: boolean;
} & SelectHTMLAttributes<any> &
  BoxProps;

const StyledSelect = styled(Box)<SelectProps>`
  display: block;
  padding: 10px 20px;
  max-width: 200px;
  width: 100%;
  font-size: 15px;
  border: none;
  border-radius: 20px;
  appearance: none;
  background: var(--form-input-disabled-color)
    url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    no-repeat;
  background-position: right 10px top 50%, 0 0;
  background-size: 35px 45px, 100%;

  &:hover {
    border-color: var(--form-input-color);
  }
  &:focus {
    outline: none;
  }

  ${s =>
    s.disabled
      ? `
        &:disabled {
          color: var(--form-input-disabled-color);
          background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%");
        }`
      : ""}
`;

const Select = forwardRef<Ref, SelectProps>((props, ref) => (
  <StyledSelect forwardedAs="select" ref={ref} {...props} />
));

export default Select;
