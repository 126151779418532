import { RefObject, useLayoutEffect } from "react";
import LeaderLine from "react-leader-line";
import { TagRef } from "../components/Tags/types";
import { HydratedField } from "../../../routes/RadarWizard/types";
import { getFieldOpacity, isLeftQuadrant, isTopQuadrant } from "../../../utils";
import { LeaderLineTag } from "./types";
import { getConnectedNodes, getLineSize, getGravityMultiplier } from "./utils";
import { GenericObject } from "../../../@types";

type LineProps = {
  sizeMap: Record<string, number>;
};

const ANCHOR_POSITION = {
  leftCenter: { x: "2%", y: "50%" }, // +- 2% offset is needed to go behind tag
  rightCenter: { x: "98%", y: "50%" },
};

export const LEADER_LINES_CONTAINER_ID = "leader-lines-container";
const LINE_SIZE_MULTIPLIER = 3;
const MAX_FIELDS_IN_QUADRANT = 10;
const PIXELS_OFFSET = 20;

const clearLeaderLines = () => {
  document.querySelectorAll(".leader-line").forEach(e => e.remove());
};

const moveLeaderLinesToRadar = () => {
  const container = document.getElementById(LEADER_LINES_CONTAINER_ID);
  document.querySelectorAll(".leader-line").forEach(e => {
    container!.appendChild(e);
  });
};

function createLeaderLine(
  fieldA: LeaderLineTag,
  fieldB: LeaderLineTag,
  fieldAAnchor: GenericObject,
  extraProps: GenericObject
) {
  try {
    new LeaderLine(
      LeaderLine.pointAnchor(fieldA.node, fieldAAnchor),
      LeaderLine.pointAnchor(fieldB.node, {
        ...(isLeftQuadrant(fieldB.field.quadrant, fieldB.field.position)
          ? ANCHOR_POSITION.rightCenter
          : ANCHOR_POSITION.leftCenter),
      }),
      extraProps
    );

    // TODO for tooltip feature
    // const linePath = document.querySelector('body>.leader-line:last-of-type path') as HTMLElement;
    // linePath.dataset.tip = "ded";
    // remove disable pointer event from global styles
    // add new line layer to the body
  } catch (e) {}
}

function createLeaderLineForNode(
  fieldA: LeaderLineTag,
  fieldB: LeaderLineTag,
  fieldAAnchor: GenericObject,
  lineProps: LineProps,
  gravityProps: GenericObject
) {
  const fieldAOpacity = getFieldOpacity(fieldA.field.weight);
  const fieldBOpacity = getFieldOpacity(fieldB.field.weight);

  const styleProps = {
    endPlug: "behind",
    size: lineProps.sizeMap[fieldB.field.id] * LINE_SIZE_MULTIPLIER,
    gradient: {
      startColor: `rgba(${fieldA.field.type.baseColor}, ${fieldAOpacity})`,
      endColor: `rgba(${fieldB.field.type.baseColor}, ${fieldBOpacity})`,
    },
  };

  const extraProps = { ...gravityProps, ...styleProps };

  // Double the lines to avoid showing BG on the opacity
  createLeaderLine(fieldA, fieldB, fieldAAnchor, {
    ...extraProps,
    gradient: {
      startColor: "var(--white)",
      endColor: "var(--white)",
    },
  });
  createLeaderLine(fieldA, fieldB, fieldAAnchor, extraProps);
}

function createLeaderLineForCurrentNode(
  fieldA: LeaderLineTag,
  fieldB: LeaderLineTag,
  lineExtraProps: LineProps
) {
  const gravityProps = {
    startSocketGravity: isLeftQuadrant(
      fieldB.field.quadrant,
      fieldB.field.position
    )
      ? [-50, 0]
      : [50, 0],
    endSocketGravity: isLeftQuadrant(
      fieldB.field.quadrant,
      fieldB.field.position
    )
      ? [50, 0]
      : [-50, 0],
  };

  const fieldAnchor = isLeftQuadrant(
    fieldB.field.quadrant,
    fieldB.field.position
  )
    ? ANCHOR_POSITION.leftCenter
    : ANCHOR_POSITION.rightCenter;

  createLeaderLineForNode(
    fieldA,
    fieldB,
    fieldAnchor,
    lineExtraProps,
    gravityProps
  );
}

function getFieldRelativePosition(field: HydratedField) {
  if (field.quadrant === 0) {
    return 1;
    // TODO Check this place potential issue
  }

  if (isTopQuadrant(field.quadrant)) {
    return MAX_FIELDS_IN_QUADRANT - parseInt(field.position, 10) + 1;
  }

  return parseInt(field.position);
}

function getFieldPosDelta(absDelta: number) {
  const sizeMultiplier = getGravityMultiplier();
  // Ideally we should not use magic numbers and consider some algo
  const smallerFieldDelta = absDelta * 2.2 + 2;
  const biggerFieldDelta = absDelta / 4 + 2;
  //const biggerFieldDelta = Math.sqrt(absDelta) + 2; // To consider

  return [
    smallerFieldDelta * sizeMultiplier,
    biggerFieldDelta * sizeMultiplier,
  ];
}

function calculateFieldGravityOffset(
  fieldA: HydratedField,
  fieldB: HydratedField
) {
  let fieldAPosDelta;
  let fieldBPosDelta;

  const fieldAPos = getFieldRelativePosition(fieldA);
  const fieldBPos = getFieldRelativePosition(fieldB);

  const absDelta = Math.abs(fieldAPos - fieldBPos);

  if (fieldAPos > fieldBPos) {
    [fieldBPosDelta, fieldAPosDelta] = getFieldPosDelta(absDelta);
  } else {
    [fieldAPosDelta, fieldBPosDelta] = getFieldPosDelta(absDelta);
  }

  const gravityADelta = fieldAPosDelta * PIXELS_OFFSET;
  const gravityBDelta = fieldBPosDelta * PIXELS_OFFSET;

  return { gravityADelta, gravityBDelta };
}

function createLeaderLineForOtherNode(
  fieldA: LeaderLineTag,
  fieldB: LeaderLineTag,
  lineExtraProps: LineProps
) {
  const { gravityADelta, gravityBDelta } = calculateFieldGravityOffset(
    fieldA.field,
    fieldB.field
  );

  const gravityProps = {
    startSocketGravity: isLeftQuadrant(
      fieldA.field.quadrant,
      fieldA.field.position
    )
      ? [gravityADelta, 0]
      : [-gravityADelta, 0],
    endSocketGravity: isLeftQuadrant(
      fieldB.field.quadrant,
      fieldB.field.position
    )
      ? [gravityBDelta, 0]
      : [-gravityBDelta, 0],
  };

  const fieldAnchor = isLeftQuadrant(
    fieldA.field.quadrant,
    fieldA.field.position
  )
    ? ANCHOR_POSITION.rightCenter
    : ANCHOR_POSITION.leftCenter;

  createLeaderLineForNode(
    fieldA,
    fieldB,
    fieldAnchor,
    lineExtraProps,
    gravityProps
  );
}

export function useLeaderLines(
  isPreviewEnabled: boolean,
  fields: HydratedField[],
  fieldsMap: Record<string, HydratedField>,
  renderedNodes: TagRef[],
  currentFieldNode: RefObject<HTMLOrSVGElement>,
  currentField?: HydratedField,
  showOnlyImportantConnections?: boolean,
  isSummaryPage?: boolean
) {
  useLayoutEffect(() => {
    clearLeaderLines();

    if (renderedNodes.length) {
      if (currentField && currentFieldNode.current) {
        // Show lines for current field only
        const currentLeaderLineTag = {
          node: currentFieldNode.current,
          field: currentField,
        };

        const connectedNodes = getConnectedNodes(renderedNodes, currentField);
        const sizeMap = getLineSize(currentField);
        connectedNodes.forEach(node => {
          createLeaderLineForCurrentNode(currentLeaderLineTag, node, {
            sizeMap,
          });
        });
      } else if (isPreviewEnabled || isSummaryPage) {
        // Show many to many lines
        renderedNodes.forEach(node => {
          const field = fieldsMap[node.props.field.id];
          if (field.isSelected) {
            const connectedNodes = getConnectedNodes(
              renderedNodes,
              field,
              showOnlyImportantConnections
            );
            const sizeMap = getLineSize(field);
            connectedNodes.forEach(connectedNode => {
              createLeaderLineForOtherNode(
                {
                  node: node.node,
                  field: field,
                },
                connectedNode,
                {
                  sizeMap,
                }
              );
            });
          }
        });
      }
    }

    moveLeaderLinesToRadar();
  }, [
    renderedNodes,
    currentField,
    currentFieldNode,
    fields,
    fieldsMap,
    isPreviewEnabled,
    showOnlyImportantConnections,
    isSummaryPage,
  ]);
}
