import { HorizonSelectorProps } from "./types";
import { ClickableText } from "./styled";
import { HORIZON_MODE } from "../../../../constants";
import { HorizonMode } from "../../../../@types";

function HorizonSelector({ onModeChanged, mode }: HorizonSelectorProps) {
  return (
    <svg
      display="none"
      x={853}
      y={990}
      width="214.229"
      height="70.312"
      viewBox="0 0 214.229 70.312"
    >
      <rect
        width="214.229"
        height="34.712"
        transform="translate(0 35.6)"
        fill="var(--white)"
      />
      <text
        transform="translate(49.411 15)"
        fill="rgba(0,0,0,0.5)"
        fontSize="16"
        fontFamily="Roboto-Regular, Roboto"
      >
        <tspan x="0" y="0">
          ZEITHORIZONT
        </tspan>
      </text>
      <ClickableText
        onClick={() => onModeChanged(HORIZON_MODE.long as HorizonMode)}
        transform="translate(132.421 57.45)"
        fill={`rgba(1,51,102, ${mode === HORIZON_MODE.long ? "1" : "0.3"})`}
        fontSize="15.83"
        fontFamily="Roboto-Bold, Roboto"
        fontWeight="700"
      >
        <tspan x="0" y="0">
          LANG
        </tspan>
      </ClickableText>
      {mode === HORIZON_MODE.short ? (
        <path
          data-name="short-mode"
          d="M1045.543,1040.41c0-6.906-4.627-12.512-10.325-12.512h-46.23c-5.7,0-10.325,5.606-10.325,12.512s4.627,12.511,10.325,12.511h46.23C1040.916,1052.921,1045.543,1047.315,1045.543,1040.41Z"
          transform="translate(-932.949 -988.71)"
          fill="none"
          stroke="rgb(0, 23, 31)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="1.5"
          strokeWidth="2"
          fillRule="evenodd"
        />
      ) : (
        <path
          data-name="long-mode"
          d="M1045.543,1040.41c0-6.906-4.627-12.512-10.325-12.512h-46.23c-5.7,0-10.325,5.606-10.325,12.512s4.627,12.511,10.325,12.511h46.23C1040.916,1052.921,1045.543,1047.315,1045.543,1040.41Z"
          transform="translate(-856.949 -988.71)"
          fill="none"
          stroke="rgb(0, 23, 31)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="1.5"
          strokeWidth="2"
          fillRule="evenodd"
        />
      )}
      <path
        d="M1059.7,1040.41a12.517,12.517,0,0,0-12.511-12.512H928.908a12.511,12.511,0,0,0,0,25.023h118.281A12.517,12.517,0,0,0,1059.7,1040.41Z"
        transform="translate(-870.949 -988.71)"
        fill="none"
        stroke="rgba(1,51,102,0.2)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="1.5"
        strokeWidth="2"
        fillRule="evenodd"
      />
      <ClickableText
        onClick={() => onModeChanged(HORIZON_MODE.short as HorizonMode)}
        transform="translate(59.711 57.45)"
        fill={`rgba(1,51,102, ${mode === HORIZON_MODE.short ? "1" : "0.3"})`}
        fontSize="15.83"
        fontFamily="Roboto-Bold, Roboto"
        fontWeight="700"
      >
        <tspan x="0" y="0">
          KURZ
        </tspan>
      </ClickableText>
    </svg>
  );
}
export default HorizonSelector;
