import { makeVar } from "@apollo/client";
import { SelectionMap, HorizonMode } from "../@types";
import { HORIZON_MODE } from "../constants";

export type AppState = {
  sheetId?: string;
  localeId?: string;
  selectedPreset: string;
  selectedFields: SelectionMap;
  selectedKpis: SelectionMap;
  selectedHorizon: HorizonMode;
  showOnlyImportantConnections: boolean;
};

export const selectedPresetVar = makeVar<string>("");
export const selectedFieldsVar = makeVar<SelectionMap>({});
export const selectedKpisVar = makeVar<SelectionMap>({});
export const selectedHorizonVar = makeVar<HorizonMode>(
  HORIZON_MODE.short as HorizonMode
);
export const showOnlyImportantConnectionsVar = makeVar<boolean>(false);
export const lastIntroStepVar = makeVar<string>("");
