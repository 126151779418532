import { useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import {
  DtoField,
  HydratedField,
  WeightByFieldMap,
  KpiByFieldMap,
  MatrixObject,
  FieldsConnectionMap,
  FieldTypesMap,
} from "../types";
import {
  selectedFieldsVar,
  selectedKpisVar,
  selectedPresetVar,
} from "../../../apollo/state";
import {
  getFieldErrors,
  getFieldsMap,
  getManyToManyConnections,
  getStaticTopConnections,
  useUrlMatching,
} from "../utils";
import { EMPTY_MATRIX } from "./constants";
import { STEP_URLS } from "../../../constants";
import { EXPORT_URLS } from "../../../constants/routing";

export function useHydratedFields(
  fields: DtoField[],
  fieldTypesMap: FieldTypesMap,
  weightByFieldMap: WeightByFieldMap,
  kpiByFieldMap: KpiByFieldMap,
  matrix: MatrixObject<number>,
  connectionLabels: MatrixObject<string>
) {
  const selectedPreset = useReactiveVar(selectedPresetVar);
  const selectedFields = useReactiveVar(selectedFieldsVar);
  const selectedKpis = useReactiveVar(selectedKpisVar);
  const shouldUseManyToManyConnections = useUrlMatching([
    STEP_URLS.summary,
    STEP_URLS.preview,
    EXPORT_URLS.image,
  ]);

  const fieldsMap = useMemo(() => {
    return getFieldsMap<DtoField>(fields);
  }, [fields]);

  const staticFieldsConnectionsMap = useMemo<FieldsConnectionMap>(() => {
    if (matrix !== EMPTY_MATRIX && connectionLabels !== EMPTY_MATRIX) {
      return getStaticTopConnections(matrix, connectionLabels);
    }

    return EMPTY_MATRIX;
  }, [matrix, connectionLabels]);

  const manyToManyFieldsConnectionsMap = useMemo<FieldsConnectionMap>(() => {
    if (
      shouldUseManyToManyConnections &&
      matrix !== EMPTY_MATRIX &&
      connectionLabels !== EMPTY_MATRIX
    ) {
      return getManyToManyConnections(
        matrix,
        connectionLabels,
        selectedFields,
        staticFieldsConnectionsMap
      );
    }

    return EMPTY_MATRIX;
  }, [
    matrix,
    connectionLabels,
    selectedFields,
    staticFieldsConnectionsMap,
    shouldUseManyToManyConnections,
  ]);

  const fieldsConnectionsMap = shouldUseManyToManyConnections
    ? manyToManyFieldsConnectionsMap
    : staticFieldsConnectionsMap;

  const hydratedFields = useMemo(() => {
    if (!Object.keys(weightByFieldMap).length) {
      return [];
    }

    return fields.map<HydratedField>(field => {
      const fieldKpis = kpiByFieldMap[field.id].map(kpi => {
        return {
          ...kpi,
          isSelected: !!selectedKpis[kpi.id],
        };
      });

      const connectedFields = fieldsConnectionsMap[field.id].map(cn => {
        const dtoField = fieldsMap[cn.field];
        return {
          ...dtoField,
          type: fieldTypesMap[dtoField.typeId],
          weight: weightByFieldMap[cn.field][selectedPreset].value,
          connectionValue: cn.value,
          connectionLabel: cn.label,
          size: cn.size,
        };
      });

      const isSelected = !!selectedFields[field.id];
      const { importantConnectionNotSelected } = getFieldErrors(
        isSelected,
        selectedFields,
        connectedFields
      );

      return {
        ...field,
        type: fieldTypesMap[field.typeId],
        weight: weightByFieldMap[field.id][selectedPreset].value,
        isSelected,
        KPIs: fieldKpis,
        matrix,
        connectedFields,
        importantConnectionNotSelected,
      };
    });
  }, [
    fields,
    fieldsMap,
    fieldTypesMap,
    weightByFieldMap,
    selectedPreset,
    selectedFields,
    kpiByFieldMap,
    matrix,
    fieldsConnectionsMap,
    selectedKpis,
  ]);

  const hydratedFieldsMap = useMemo(() => {
    return getFieldsMap<HydratedField>(hydratedFields);
  }, [hydratedFields]);

  return {
    hydratedFields,
    hydratedFieldsMap,
  };
}
