import { forwardRef } from "react";
import { CurrentTagGContainer, Text, TextContainer } from "./styled";
import { TagProps } from "./types";

const CurrentTag = forwardRef(
  (
    {
      field: { name, description, quadrant, type },
      x,
      y,
      lines,
      bgOpacity,
      isTagClickEnabled,
      isTooltipEnabled,
      onClick,
    }: TagProps,
    ref: any
  ) => {
    const backgroundColor = `rgba(${type.baseColor}, ${bgOpacity})`;

    return (
      <CurrentTagGContainer ref={ref} width="228" height="52">
        <path
          d="M1149.28-602.61a26.255,26.255,0,0,0-26.24-26.242H946.721a26.255,26.255,0,0,0-26.243,26.242,26.254,26.254,0,0,0,26.243,26.242H1123.04A26.254,26.254,0,0,0,1149.28-602.61Z"
          transform="translate(-920.478 628.852)"
          fill="var(--white)"
          fillRule="evenodd"
        />
        <path
          d="M1149.28-602.61a26.255,26.255,0,0,0-26.24-26.242H946.721a26.255,26.255,0,0,0-26.243,26.242,26.254,26.254,0,0,0,26.243,26.242H1123.04A26.254,26.254,0,0,0,1149.28-602.61Z"
          transform="translate(-920.478 628.852)"
          fill={backgroundColor}
          fillRule="evenodd"
        />
        <g>
          <foreignObject x="0" y="0" width="228" height="52">
            <TextContainer>
              <Text
                lines={lines}
                sizeMultiplier={1.2}
                length={name.length}
                dangerouslySetInnerHTML={{ __html: name }}
              />
            </TextContainer>
          </foreignObject>
        </g>
      </CurrentTagGContainer>
    );
  }
);

export default CurrentTag;
