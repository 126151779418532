import Box, { BoxProps } from "../../../components/Box";

function HexagonIcon(props: BoxProps) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="276.586"
      height="273.595"
      viewBox="0 0 276.586 273.595"
      {...props}
    >
      <g id="Group_4" data-name="Group 4" transform="translate(-64.5 -66.699)">
        <path
          id="Path_11"
          data-name="Path 11"
          d="M182.7,110.2H103.9L64.5,178.441l39.4,68.242h78.8l39.4-68.242Z"
          transform="translate(0 25.055)"
          fill="#b4a36f"
        />
        <path
          id="Path_12"
          data-name="Path 12"
          d="M258.2,66.7H179.4L140,134.941l39.4,68.242h78.8l39.4-68.242Z"
          transform="translate(43.487)"
          fill="#ececec"
        />
        <path
          id="Path_13"
          data-name="Path 13"
          d="M258.2,153.7H179.4L140,221.941l39.4,68.242h78.8l39.4-68.242Z"
          transform="translate(43.487 50.111)"
          fill="#d0d0d0"
        />
      </g>
    </Box>
  );
}

export default HexagonIcon;
