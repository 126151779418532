import {
  AllHTMLAttributes,
  ElementType,
  forwardRef,
  ReactNode,
  SVGAttributes,
} from "react";
import styled from "styled-components";
import { Ref } from "../../@types/index";

type Styles =
  | Partial<CSSStyleDeclaration>
  | { [key: string]: Partial<CSSStyleDeclaration> };

const toCSS = (styles: Styles): string =>
  Object.entries(styles)
    .map(([k, v]) =>
      k.match(/& |@media|:/)
        ? `${k} {${toCSS(v)}}`
        : `${k.replace(/[A-Z]/g, match => `-${match.toLowerCase()}`)}:${v}`
    )
    .join(";");

export type BoxProps = {
  sx?: Styles;
  children?: ReactNode;
  m?: string;
  p?: string;
  as?: ElementType;
} & AllHTMLAttributes<any> &
  SVGAttributes<any>;

const StyledBox = styled.div<BoxProps>`
  margin: ${s => s.m && s.m};
  padding: ${s => s.p && s.p};
  ${s => (s.sx ? `&& {${toCSS(s.sx)}}` : "")}
`;

const Box = forwardRef<Ref, BoxProps>((props, ref) => (
  <StyledBox {...props} ref={ref} />
));

export default Box;
