import Typography from "../Typography";
import { getFieldOpacity } from "../../utils";
import { TagWrapper } from "./styled";
import { DtoFieldType } from "../../routes/RadarWizard/types";

type TagProps = {
  name: string;
  type: DtoFieldType;
  quadrant: number;
  weight: number;
  pointer?: boolean;
  onClick?: () => void;
};

const LINES_TO_FONT = [15, 14, 11];

function Tag({ name, type, weight, pointer, onClick }: TagProps) {
  const opacity = getFieldOpacity(weight);

  const backgroundColor = `rgba(${type.baseColor}, ${opacity})`;
  const borderColor = `rgb(${type.baseColor})`;

  const brCount = (name.match(/<br>/g) || []).length;
  const lines = brCount || Math.ceil(name.length / 18);
  const fontSize =
    LINES_TO_FONT[lines] || LINES_TO_FONT[LINES_TO_FONT.length - 1];

  return (
    <TagWrapper
      onClick={onClick}
      backgroundColor={backgroundColor}
      pointer={pointer}
      borderColor={borderColor}
    >
      <Typography
        size={`${fontSize}px`}
        as="span"
        lh={`${fontSize}px`}
        ta="center"
        dangerouslySetInnerHTML={{ __html: name }}
      />
    </TagWrapper>
  );
}

export default Tag;
