import { createGlobalStyle } from "styled-components";
import {
  useConfigurableStyles,
  ConfigurableStyles,
} from "./context/ConfigurableStyles";

export const GlobalStylesRoot = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  body {
    font-family: var(--base-text-font-type);
    color: var(--base-text-color);
  }

  html, body, #root {
    height: 100%;
    min-height: 768px;
  }

  p {
    margin: 0 0 .35em;
  }

  a {
    color: var(--link-color);
  }
`;

export const GlobalStylesVars = createGlobalStyle<ConfigurableStyles>`
  :root {
    --base-text-color: ${props => props.baseTextColor || "#00171F"};
    --base-text-font-type: ${props =>
      props.baseTextFontType || "'Roboto', sans-serif"};
    --secondary-text-color: ${props => props.secondaryTextColor || "#CCCCCC"};
    --headline-color: ${props => props.headlineColor || "#00171F"};
    --headline-text-font-type: ${props =>
      props.headlineTextFontType || "'Lato', sans-serif"};
    --button-background-rgb: ${props =>
      props.buttonBackgroundRgb || "0, 23, 31"};
    --button-color: ${props => props.buttonColor || "#FFFFFF"};
    --form-input-color: ${props => props.formInputColor || "#00171F"};
    --form-input-disabled-color: ${props =>
      props.formInputDisabledColor || "#CCCCCC"};
    --link-color: ${props => props.linkColor || "#03468B"};
    --tooltip-color: ${props => props.tooltipColor || "#03468B"};
    --loader-color: ${props => props.loaderColor || "#008962;"};

    --radar-top-color: ${props =>
      props.radarTopColor || "#00bc87"}; //008962/00bc87
    --radar-middle-color: ${props => props.radarMiddleColor || "#00000033"};
    --radar-bottom-color: ${props => props.radarBottomColor || "#ee0000"};
    --radar-labels-color: ${props => props.radarLabelsColor || "#00000080"};

    --radar-tag-text-color: var(--base-text-color);
    --white-rgb: 255, 255, 255;
    --white: rgb(var(--white-rgb));
    --grey: rgb(208, 208, 208);
    --box-shadow: -10px 0px 10px 1px #00000055;

    --icon_exclamation: var(--base-text-color);
    --icon_arrow-right: var(--base-text-color);

    --icon_tag-warning-background: #fff;
    --icon_tag-warning-color: var(--base-text-color);

    --icon_tag-selected-background: #fff;
    --icon_tag-selected-color: var(--base-text-color);

    --icon_sidebar-warning-background: var(--base-text-color);
    --icon_sidebar-warning-color: #fff;

    --icon_radar-tooltip-exclamation: var(--base-text-color);
  }
`;

export function GlobalStyles() {
  const styles = useConfigurableStyles();

  return (
    <>
      <GlobalStylesRoot />
      <GlobalStylesVars {...styles} />
    </>
  );
}
