import React, { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { getCustomizationParams } from "../utils";
import { CustomizationVariables } from "../@types";

export type ConfigurableStyles = {
  baseTextColor?: string;
  baseTextFontType?: string;
  secondaryTextColor?: string;
  headlineColor?: string;
  headlineTextFontType?: string;
  buttonBackgroundRgb?: string;
  buttonColor?: string;
  formInputColor?: string;
  formInputDisabledColor?: string;
  loaderColor?: string;
  linkColor?: string;
  tooltipColor?: string;
  welcomeLogoUrl?: string;
  radarTopColor?: string;
  radarMiddleColor?: string;
  radarBottomColor?: string;
  radarLabelsColor?: string;
  radarLogoUrl?: string;
};

type ConfigurableStylesResponse = {
  styles: {
    name: keyof ConfigurableStyles;
    value: string;
  }[];
};

export const GET_STYLES = gql`
  query getStyles($sheetId: String, $localeId: String) {
    styles(sheetId: $sheetId, localeId: $localeId) {
      name
      value
    }
  }
`;

const configurableStylesContext = React.createContext<ConfigurableStyles>({});
const Provider = configurableStylesContext.Provider;

export function ConfigurableStylesProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const customizationParams = getCustomizationParams();
  const { data } = useQuery<ConfigurableStylesResponse, CustomizationVariables>(
    GET_STYLES,
    { variables: customizationParams }
  );

  const styles = useMemo<ConfigurableStyles>(() => {
    if (!data) {
      return {};
    }

    return data.styles.reduce<ConfigurableStyles>((acc, item) => {
      acc[item.name] = item.value;
      return acc;
    }, {});
  }, [data]);

  return <Provider value={styles}>{children}</Provider>;
}

export const useConfigurableStyles = () => {
  const context = React.useContext(configurableStylesContext);
  if (context === undefined) {
    throw new Error(
      "useConfigurableStyles must be used within a ConfigurableStylesProvider"
    );
  }
  return context;
};
