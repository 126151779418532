import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import toast from "react-hot-toast";

import { cacheConfig } from "./cache";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    toast.error("Anforderungsfehler", { duration: 20000 });
  }
});

export const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(cacheConfig),
});
