import PuffLoader from "react-spinners/PuffLoader";
import { GlobalLoaderWrapper } from "./styled";

export function GlobalLoader() {
  return (
    <GlobalLoaderWrapper>
      <PuffLoader loading={true} size={300} color="var(--loader-color)" />
    </GlobalLoaderWrapper>
  );
}

export function InlineLoader() {
  return <PuffLoader loading={true} size={30} color="var(--loader-color)" />;
}
