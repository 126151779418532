import { forwardRef } from "react";
import { renderToString } from "react-dom/server";
import striptags from "striptags";
import Box from "../../../Box";
import { InfoIcon } from "../../../icons";
import Typography from "../../../Typography";
import { SvgContainer, Text, TextContainer } from "./styled";
import { TagProps } from "./types";
import { useTranslation } from "react-i18next";
import { SelectedIcon, WarningIcon, LockIcon } from "./icons";

const Tag = forwardRef(
  (
    {
      field: { name, description, quadrant, isSelected, type, locked },
      x,
      y,
      lines,
      bgOpacity,
      isTagClickEnabled,
      isTooltipEnabled,
      isCurrent,
      warnings,
      onClick,
    }: TagProps,
    ref: any
  ) => {
    const { t } = useTranslation();

    const backgroundColor = `rgba(${type.baseColor}, ${bgOpacity})`;
    const borderColor = isCurrent
      ? "var(--current-tag-border)"
      : `rgb(${type.baseColor})`;

    return (
      <SvgContainer
        ref={ref}
        width="190"
        height="37"
        viewBox="0 0 190 37"
        x={x}
        y={y}
        isTagClickEnabled={isTagClickEnabled}
        isTooltipEnabled={isTooltipEnabled}
        onClick={onClick}
      >
        <path
          d="M202.69,472.005a17.645,17.645,0,0,0-17.77-17.5H32.46a17.5,17.5,0,1,0,0,35H184.92a17.645,17.645,0,0,0,17.77-17.5Z"
          transform="translate(-13.69, -453.505)"
          fill="#fff"
          strokeWidth="0"
          fillRule="evenodd"
        />
        <path
          d="M202.69,472.005a17.645,17.645,0,0,0-17.77-17.5H32.46a17.5,17.5,0,1,0,0,35H184.92a17.645,17.645,0,0,0,17.77-17.5Z"
          transform="translate(-13.69, -453.505)"
          fill={backgroundColor}
          stroke={borderColor}
          strokeWidth="2"
          fillRule="evenodd"
        />
        <g>
          <foreignObject x="1" y="1" width="188" height="35">
            <TextContainer
              data-html={true}
              data-multiline={true}
              data-arrow-color="#fff"
              data-background-color="#fff"
              data-tip-disable={!isTooltipEnabled}
              data-tip={renderToString(
                <Box
                  sx={{
                    color: "var(--tooltip-color)",
                    maxWidth: "300px",
                    padding: "15px",
                  }}
                >
                  <Typography
                    bold
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    <InfoIcon />
                    {striptags(name, [], " ")}
                  </Typography>
                  <Typography lh="19px">{description}</Typography>
                </Box>
              )}
            >
              <Text
                lines={lines}
                sizeMultiplier={1}
                isSelected={
                  isSelected || warnings.length > 0 || locked
                } /*With warning same behaviour as with selected*/
                length={name.length}
                dangerouslySetInnerHTML={{ __html: name }}
              />
            </TextContainer>
          </foreignObject>
        </g>
        {locked && <LockIcon />}
        {!locked && isSelected && <SelectedIcon />}
        {!locked && warnings.length && (
          <WarningIcon title={warnings.map(w => t(w)).join("\n")} />
        )}
      </SvgContainer>
    );
  }
);

export default Tag;
