import { forwardRef, ReactNode } from "react";
import { Ref } from "../../@types";
import Typography from "../Typography";

type Props = {
  children?: ReactNode;
};

const SectionTitle = forwardRef<Ref, Props>((props, ref) => (
  <Typography
    size="35px"
    ls="1.11px"
    weight="500"
    m="0 0 30px"
    uppercase
    color="headline-color"
    ref={ref}
    {...props}
  />
));

export default SectionTitle;
