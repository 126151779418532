import { useEffect, useState } from "react";
import { noop } from "lodash-es";
import { useTranslation } from "react-i18next";
import { GlobalLoader } from "../../components/Loader";
import Radar from "../../components/Radar";
import { RadarWizardWrapper } from "./../RadarWizard/styled";
import { useData } from "./../RadarWizard/hooks/useData";
import { useHydratedFields } from "./../RadarWizard/hooks/useHydratedFields";
import {
  Header,
  Footer,
  ExportImage,
  RadarExportWrapper,
  DateCaption,
  Page,
} from "./styled";
import { getResourceBlob } from "../RadarWizard/Summary/utils";
import { EXPORT_API_URLS } from "../../constants/routing";
import { setAppState } from "../../utils";
import { getAppStateFromUrl, isValidState, formatCaption } from "./utils";

type RadarExportProps = {
  mode: string;
};

function RadarExport({ mode }: RadarExportProps) {
  const { t } = useTranslation();
  const {
    dataInitialized,
    fields,
    fieldTypesMap,
    weightByFieldMap,
    kpiByFieldMap,
    matrix,
    connectionLabels,
  } = useData();
  const { hydratedFields, hydratedFieldsMap } = useHydratedFields(
    fields,
    fieldTypesMap,
    weightByFieldMap,
    kpiByFieldMap,
    matrix,
    connectionLabels
  );
  const [image, setImage] = useState("");

  useEffect(() => {
    const parsedState = getAppStateFromUrl();
    if (mode === "pdf") {
      getResourceBlob(EXPORT_API_URLS.image, parsedState)
        .then(blob => setImage(URL.createObjectURL(blob)))
        .catch(() => true);
    } else {
      if (isValidState(parsedState)) {
        window.cspGravityMultiplier = 2.3;
        window.cspLineSizeMultiplier = 2;
        setAppState(parsedState);
      }
    }
  }, [mode]);

  if (!dataInitialized) {
    return <GlobalLoader />;
  }

  return (
    <RadarWizardWrapper>
      <RadarExportWrapper>
        {mode === "image" ? (
          <Radar
            isTooltipEnabled={false}
            isTagClickEnabled={false}
            isPreviewEnabled={true}
            fields={hydratedFields}
            fieldsMap={hydratedFieldsMap}
            onTagClicked={noop}
            currentField={undefined}
            fieldErrors={{
              importantFieldNotSelected: [],
              importantConnectionNotSelected: [],
            }}
          />
        ) : (
          <Page>
            <Header>{formatCaption(t("export.pdf.header", "Header"))}</Header>
            <ExportImage src={image} alt="" />
            <Footer>{formatCaption(t("export.pdf.footer", "Footer"))}</Footer>
            <DateCaption>
              {new Date(Date.now())
                .toLocaleDateString("en-GB")
                .replaceAll("/", ".")}
            </DateCaption>
          </Page>
        )}
      </RadarExportWrapper>
    </RadarWizardWrapper>
  );
}

export default RadarExport;
