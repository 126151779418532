import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { getCustomizationParams } from "./utils";
import { defaultLanguage } from "./i18-customization-config";

const customizationParams = getCustomizationParams();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpBackend)
  .init({
    lng: customizationParams.localeId || defaultLanguage,
    fallbackLng: defaultLanguage,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
  });

export default i18n;
