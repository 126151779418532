import { useTranslation, Trans } from "react-i18next";
import fileDialog from "file-dialog";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Typography from "../../../components/Typography";
import { STEP_URLS } from "../../../constants";
import HexagonIcon from "./HexagonIcon";
import { StepWrapper } from "../styled";
import {
  FunkFoundationWrapper,
  FunkText,
  FunkLogo,
  FunkDescription,
  UnderlinedButton,
} from "./styled";
import { INTRO_STEP } from "../../../constants/routing";
import { setAppState } from "../../../utils";
import { AppState } from "../../../apollo/state";
import funkLogo from "./fs-logo.png";
import { useNavigateWithState } from "../../../utils/useNavigateWithState";
import qs from "qs";
import { useConfigurableStyles } from "../../../context/ConfigurableStyles";
import { useSettings } from "../../../context/Settings";

function Welcome() {
  const navigate = useNavigateWithState();
  const { t } = useTranslation();
  const styles = useConfigurableStyles();
  const { demoMode } = useSettings();

  function handleStartClick() {
    navigate(STEP_URLS.weighting);
  }

  function handleIntroClick() {
    navigate(STEP_URLS.intro.replace(INTRO_STEP, "1"));
  }

  function handleLoadClick() {
    fileDialog({ accept: [".csp"] }).then(files => {
      const reader = new FileReader();
      reader.readAsText(files[0]);
      reader.onload = () => {
        const config = JSON.parse(reader.result as string) as AppState;
        setAppState(config);
        const { sheetId, localeId } = config;
        const urlParams = qs.stringify({ sheetId, localeId });
        navigate(`${STEP_URLS.weighting}?${urlParams}`);
        window.location.reload();
      };
    });
  }

  function handleLoadFunkPage() {
    window.open("https://www.funk-stiftung.org/de/", "_blank")?.focus();
  }

  const digitalText = t("wizard.welcome.digitalText"); // Development and operation of this application are supported by the
  const digitalLink = t("wizard.welcome.digitalLink"); // Funk Foundation

  return (
    <StepWrapper sx={{ width: "70%" }}>
      {(digitalText || digitalLink) && (
        <FunkFoundationWrapper>
          <FunkText>
            <FunkDescription>{digitalText}</FunkDescription>
            {!styles.welcomeLogoUrl ? (
              <UnderlinedButton onClick={handleLoadFunkPage}>
                {digitalLink}
              </UnderlinedButton>
            ) : (
              <FunkDescription>{digitalLink}</FunkDescription>
            )}
          </FunkText>
          {!styles.welcomeLogoUrl && (
            <FunkLogo
              src={funkLogo}
              alt="Digital Quick Check"
              onClick={handleLoadFunkPage}
            />
          )}
        </FunkFoundationWrapper>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          gap: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {styles.welcomeLogoUrl && (
            <Box
              as="img"
              src={styles.welcomeLogoUrl}
              alt="Logo"
              sx={{ maxWidth: "320px" }}
            />
          )}
          <Box
            sx={{
              marginBottom: styles.welcomeLogoUrl ? "50%" : "",
            }}
          >
            <Typography
              size="38px"
              uppercase
              ls="1.2px"
              color="headline-color"
              font="headline-text-font-type"
            >
              {t("wizard.welcome.title", "Welcome to")}
            </Typography>
            <Typography
              size="70px"
              bold
              uppercase
              ls="2.4px"
              color="headline-color"
              font="headline-text-font-type"
              sx={{ marginBottom: "55px" }}
            >
              {t("wizard.welcome.subTitle", "csp radar")}
            </Typography>
            <Typography size="21px" ls="0.75px" m="0 0 25px">
              <Trans i18nKey="wizard.welcome.description">
                Finden Sie in wenigen Schritten heraus, <br />
                wie Sie Ihr Unternehmen nachhaltiger
                <br /> <strong>planen</strong> und <strong>gestalten</strong>{" "}
                können.
              </Trans>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
            }}
          >
            <div>
              <Button onClick={handleIntroClick}>START</Button>
            </div>
            {!demoMode && (
              <>
                <div>
                  <Button onClick={handleLoadClick}>
                    {t("wizard.welcome.load", "LOAD")}
                  </Button>
                </div>
                <Typography size="15px">
                  <UnderlinedButton onClick={handleStartClick}>
                    START
                  </UnderlinedButton>{" "}
                  {t("wizard.welcome.withoutIntro", "WITHOUT INTRO")}
                </Typography>
                <Typography size="14px" nowrap>
                  {t(
                    "wizard.welcome.openFile",
                    "Open the saved configuration file"
                  )}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        {!styles.welcomeLogoUrl && (
          <HexagonIcon
            m="0 0 0 auto"
            sx={{
              maxWidth: "100%",
              height: "auto",
              display: "none",
              "@media (min-width: 1024px)": {
                display: "block",
              },
            }}
          />
        )}
      </Box>
    </StepWrapper>
  );
}

export default Welcome;
