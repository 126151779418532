import { ChangeEvent, forwardRef, ReactNode } from "react";
import { Ref } from "../../@types";
import Box, { BoxProps } from "../Box";

type RadioGroupProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  children: ReactNode;
} & BoxProps;

const RadioGroup = forwardRef<Ref, RadioGroupProps>((props, ref) => (
  <Box ref={ref} {...props} />
));

export default RadioGroup;
