import { forwardRef, InputHTMLAttributes } from "react";
import styled from "styled-components";
import { Ref } from "../../@types";
import Box, { BoxProps } from "../Box";

const StyledMark = styled(Box)`
  display: inline-block;
  position: relative;
  border: 1px solid var(--form-input-color);
  width: 20px;
  height: 20px;
  left: 0;
  margin-right: 5px;
  vertical-align: middle;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transition: all 110ms;
  }
`;

const StyledInput = styled<any>(Box)`
  position: absolute;
  visibility: hidden;
  display: none;

  &:checked + ${StyledMark} {
    opacity: ${s => (s.disabled ? "0.3" : "")};

    &::after {
      content: "";
      opacity: 1;
      left: 5px;
      top: 1px;
      display: inline-block;
      height: 11px;
      width: 7px;
      display: inline-block;
      transform: rotate(45deg);
      border-bottom: 1.5px solid var(--form-input-color);
      border-right: 1.5px solid var(--form-input-color);
    }
  }
`;

type CheckboxProps = {
  markProps?: BoxProps;
} & InputHTMLAttributes<HTMLInputElement> &
  BoxProps;

const Checkbox = forwardRef<Ref, CheckboxProps>(
  ({ sx, markProps, ...rest }, ref) => (
    <>
      <StyledInput forwardedAs="input" type="checkbox" ref={ref} {...rest} />
      <StyledMark {...markProps} />
    </>
  )
);

export default Checkbox;
