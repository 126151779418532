import { forwardRef, ReactNode } from "react";
import styled from "styled-components";
import { Ref } from "../../@types";
import Box, { BoxProps } from "../Box";

type TProps = {
  size?: string;
  bold?: boolean;
  font?: string;
  children?: ReactNode;
  ls?: string;
  lh?: string;
  ta?: string;
  weight?: string;
  uppercase?: boolean;
  color?: string;
  nowrap?: boolean;
};

type TypographyProps = Omit<BoxProps, keyof TProps> & TProps;

const StyledTypography = styled(Box)<TypographyProps>`
  font-size: ${s => s.size && s.size};
  font-weight: ${s => s.bold && "bold"};
  font-family: ${s => s.font && `var(--${s.font})`};
  letter-spacing: ${s => s.ls && s.ls};
  line-height: ${s => s.lh && s.lh};
  font-weight: ${s => s.weight && s.weight};
  text-transform: ${s => s.uppercase && "uppercase"};
  color: ${s => s.color && `var(--${s.color})`};
  white-space: ${s => s.nowrap && `nowrap`};
  text-align: ${s => s.ta && s.ta};
`;

const Typography = forwardRef<Ref, TypographyProps>(({ as, ...rest }, ref) => (
  <StyledTypography forwardedAs={as} {...rest} ref={ref} />
));

export default Typography;
