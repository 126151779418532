import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { selectedFieldsVar, selectedKpisVar } from "../../../apollo/state";
import Box from "../../../components/Box";
import Typography from "../../../components/Typography";
import { getSelectedElementsCount } from "../../../utils";
import { ExclamationIcon } from "../PickKeysForField/icons";
import { STEP_URLS } from "../../../constants/routing";
import { useUrlMatching } from "../utils";

function Guide() {
  const { t } = useTranslation();
  const selectedKPIsCount = getSelectedElementsCount(
    useReactiveVar(selectedKpisVar)
  );
  const selectedFieldsCount = getSelectedElementsCount(
    useReactiveVar(selectedFieldsVar)
  );
  const isKPIsSelected = selectedKPIsCount > 0;
  const isTooManyFieldsSelected = selectedFieldsCount >= 6;
  const isWarningFieldsPages = useUrlMatching([
    STEP_URLS.preview,
    STEP_URLS.pickKeysForField,
  ]);

  return (
    <Typography size="13px" ta="center">
      {isKPIsSelected ? (
        <>
          {t(
            "wizard.guide.selectedKpisAndFields",
            `You selected {{kpi}} {{kpiLabel}} from {{field}} {{fieldLabel}}`,
            {
              kpi: selectedKPIsCount,
              kpiLabel:
                selectedKPIsCount > 1
                  ? t("wizard.guide.kpis", "KPIs")
                  : t("wizard.guide.kpi", "KPI"),
              field: selectedFieldsCount,
              fieldLabel:
                selectedFieldsCount > 1
                  ? t("wizard.guide.fields", "fields")
                  : t("wizard.guide.field", "field"),
            }
          )}
          {isTooManyFieldsSelected && isWarningFieldsPages && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <ExclamationIcon />
              <Typography lh="16px">
                {t(
                  "wizard.guide.fieldsFocusRecommended",
                  `A focus on 3-5 fields is recommended`
                )}
              </Typography>
            </Box>
          )}
        </>
      ) : (
        t(
          "wizard.guide.noFieldsSelected",
          "Select fields of action in the CSP Radar on the left or in the list at the top."
        )
      )}
    </Typography>
  );
}

export default Guide;
