export function ExclamationIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={21} height={21}>
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "var(--icon_exclamation)",
          fillOpacity: 1,
        }}
        d="M10.5.21C4.816.21.21 4.817.21 10.5c0 5.684 4.606 10.29 10.29 10.29 5.684 0 10.29-4.606 10.29-10.29C20.79 4.816 16.183.21 10.5.21Zm0 18.935a8.646 8.646 0 0 1 0-17.29 8.646 8.646 0 0 1 0 17.29Zm0 0"
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "var(--icon_exclamation)",
          fillOpacity: 1,
        }}
        d="M9.676 4.875h1.648v8.23H9.676ZM9.66 14.48h1.649v1.645H9.66Zm0 0"
      />
    </svg>
  );
}
