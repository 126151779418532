import qs from "qs";
import { AppState } from "../../apollo/state";

export function getAppStateFromUrl() {
  return qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
    parseArrays: false,
    decoder: (str, decoder, charset) => {
      const strWithoutPlus = str.replace(/\+/g, " ");
      if (charset === "iso-8859-1") {
        // unescape never throws, no try...catch needed:
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
      }

      if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str);
      }

      const keywords = {
        true: true,
        false: false,
        null: null,
        undefined,
      };
      if (str in keywords) {
        // @ts-ignore
        return keywords[str];
      }

      // utf-8
      try {
        return decodeURIComponent(strWithoutPlus);
      } catch (e) {
        return strWithoutPlus;
      }
    },
  }) as unknown as AppState;
}

export function isValidState(parsedState: AppState) {
  return (
    parsedState.selectedFields &&
    Object.keys(parsedState.selectedFields).length &&
    parsedState.selectedKpis &&
    Object.keys(parsedState.selectedKpis).length
  );
}

export function formatCaption(caption: string) {
  return caption
    .split("\n")
    .map((item, i) => <div key={`caption-${i}`}>{item.trim()}</div>);
}
