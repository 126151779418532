import { Route, Routes } from "react-router-dom";
import { GlobalLoader } from "../../components/Loader";
import Radar from "../../components/Radar";
import { STEP_URLS } from "../../constants/routing";
import { RadarWizardWrapper, RadarWrapper, WizardWrapper } from "./styled";
import Weighting from "./Weighting";
import Welcome from "./Welcome";
import PickFields from "./PickFields";
import PickKeysForField from "./PickKeysForField";
import { useData } from "./hooks/useData";
import { useHydratedFields } from "./hooks/useHydratedFields";
import {
  useCurrentField,
  useNavigateField,
  usePreviewEnabled,
  useRadarTooltipEnabled,
  useTagClickEnabled,
} from "./utils";
import Preview from "./Preview";
import { useFieldErrors } from "./hooks/useFieldErrors";
import Summary from "./Summary";

function RadarWizard() {
  const isTagClickEnabled = useTagClickEnabled();
  const isRadarTooltipEnabled = useRadarTooltipEnabled();
  const isPreviewEnabled = usePreviewEnabled();

  const {
    dataInitialized,
    fields,
    fieldTypes,
    fieldTypesMap,
    weightByFieldMap,
    availablePresets,
    kpiByFieldMap,
    matrix,
    connectionLabels,
  } = useData();

  const { hydratedFields, hydratedFieldsMap } = useHydratedFields(
    fields,
    fieldTypesMap,
    weightByFieldMap,
    kpiByFieldMap,
    matrix,
    connectionLabels
  );

  const currentField = useCurrentField(hydratedFieldsMap);
  const fieldErrors = useFieldErrors(hydratedFields);
  const navigateField = useNavigateField();

  if (!dataInitialized) {
    return <GlobalLoader />;
  }

  function handleTagClicked(fieldId: string) {
    navigateField(fieldId);
  }

  return (
    <RadarWizardWrapper>
      <RadarWrapper>
        <Radar
          isTooltipEnabled={isRadarTooltipEnabled}
          isTagClickEnabled={isTagClickEnabled}
          isPreviewEnabled={isPreviewEnabled}
          fields={hydratedFields}
          fieldsMap={hydratedFieldsMap}
          onTagClicked={handleTagClicked}
          currentField={currentField}
          fieldErrors={fieldErrors}
        />
      </RadarWrapper>
      <WizardWrapper>
        <Routes>
          <Route path={STEP_URLS.start} element={<Welcome />} />
          <Route
            path={STEP_URLS.weighting}
            element={
              <Weighting
                fields={fields}
                weightByFieldMap={weightByFieldMap}
                availablePresets={availablePresets}
              />
            }
          />
          <Route
            path={STEP_URLS.pickFields}
            element={
              <PickFields
                fieldTypes={fieldTypes}
                hydratedFields={hydratedFields}
                onTagClicked={handleTagClicked}
              />
            }
          />
          <Route
            path={STEP_URLS.pickKeysForField}
            element={
              <PickKeysForField
                currentField={currentField}
                hydratedFields={hydratedFields}
              />
            }
          />
          <Route
            path={STEP_URLS.preview}
            element={
              <Preview
                fieldsMap={hydratedFieldsMap}
                fieldErrors={fieldErrors}
              />
            }
          />
          <Route
            path={STEP_URLS.summary}
            element={<Summary availablePresets={availablePresets} />}
          />
        </Routes>
      </WizardWrapper>
    </RadarWizardWrapper>
  );
}

export default RadarWizard;
