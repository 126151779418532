import styled from "styled-components";

export const RadarExportWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const ExportImage = styled.img`
  width: 100%;
  height: auto%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Header = styled.div`
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 50px;
`;

export const Footer = styled.div`
  font-size: 14px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
`;

export const DateCaption = styled.div`
  font-size: 14px;
  text-align: right;
`;

export const Page = styled.div`
  padding: 40px 20px;
`;
