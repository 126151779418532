import download from "downloadjs";
import { getAppState } from "../../../utils";

export async function getResourceBlob(url = "", data = {}) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.blob();
}

export async function downloadResource(url: string, fileName: string) {
  const appState = getAppState();
  const data = await getResourceBlob(url, appState);
  return download(data, fileName);
}
