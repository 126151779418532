import { useReactiveVar } from "@apollo/client";
import { omit } from "lodash-es";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { selectedFieldsVar, selectedKpisVar } from "../../../apollo/state";
import {
  Accordion,
  AccordionDetail,
  AccordionSummary,
} from "../../../components/Accordion";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import Tag from "../../../components/Tag";
import Typography from "../../../components/Typography";
import { STEP_URLS } from "../../../constants/routing";
import { getSelectedElementsCount } from "../../../utils";
import { getCurrFieldKPIsCount } from "../../../utils/index";
import Guide from "../Guide";
import { ButtonsContainer, StepWrapper } from "../styled";
import { HydratedField } from "../types";
import { useNavigateField, hasAllFieldTypeSelected } from "../utils";
import { ExclamationIcon } from "./icons";
import { useNavigateWithState } from "../../../utils/useNavigateWithState";
import { useSettings } from "../../../context/Settings";
import { LockedInfo } from "../../../components/LockedInfo";

type Props = {
  currentField?: HydratedField;
  hydratedFields: HydratedField[];
};

function PickKeysForField(props: Props) {
  const { currentField, hydratedFields } = props;
  const { t } = useTranslation();
  const navigate = useNavigateWithState();
  const { lockedFieldText } = useSettings();
  const selectedKPIs = useReactiveVar(selectedKpisVar);
  const selectedFields = useReactiveVar(selectedFieldsVar);
  const navigateField = useNavigateField();

  const selectedKPIsCount = getSelectedElementsCount(selectedKPIs);
  const isKPIsSelected = selectedKPIsCount > 0;
  const selectedKpisForCurrentField = getCurrFieldKPIsCount(
    currentField!.KPIs,
    selectedKPIs
  );
  const isCurrKPIsSelected = selectedKpisForCurrentField > 0;
  const oneFieldPerTypeIsNotSelected = !hasAllFieldTypeSelected(hydratedFields);

  if (!currentField) {
    return null;
  }

  function handleKpiSelection(id: string, checked: boolean) {
    const newKpisState = { ...selectedKPIs, [id]: checked };
    selectedKpisVar(newKpisState);

    const selectedKpisForCurrentField = getCurrFieldKPIsCount(
      currentField!.KPIs,
      newKpisState
    );

    if (selectedKpisForCurrentField < 2) {
      selectedFieldsVar({
        ...selectedFields,
        [currentField!.id]: selectedKpisForCurrentField !== 0,
      });
    }
  }

  return (
    <StepWrapper>
      <Box sx={{ height: "100%", overflow: "auto" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
          m="0 0 20px"
        >
          <Typography>{t("wizard.pickKeysForField.field", "Field")}</Typography>
          <Tag
            type={currentField.type}
            name={currentField.name}
            quadrant={currentField.quadrant}
            weight={currentField.weight}
          />
        </Box>
        <Typography lh="20px" m="0 0 20px">
          <Box
            as="span"
            sx={{
              margin: "-2px 0 0 -20px",
              fontSize: "24px",
              position: "absolute",
            }}
          >
            &#8505;
          </Box>
          {currentField.description}
        </Typography>
        <Box m="0 0 20px">
          <Typography>
            {t("wizard.pickKeysForField.connectedWith", "Connected with")}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "25px 15px",
              marginTop: "20px",
            }}
          >
            {currentField.connectedFields.map(f => (
              <Fragment key={f.id}>
                <Tag
                  type={f.type}
                  name={f.name}
                  quadrant={f.quadrant}
                  weight={f.weight}
                  onClick={() => {
                    navigateField(f.id);
                  }}
                />
                <Typography
                  size="14px"
                  dangerouslySetInnerHTML={{
                    __html: f.connectionLabel,
                  }}
                />
              </Fragment>
            ))}
          </Box>
        </Box>
        {currentField.locked ? (
          <LockedInfo>{lockedFieldText}</LockedInfo>
        ) : (
          <>
            <Box m="0 0 10px">
              <Checkbox
                disabled
                checked
                markProps={{ sx: { border: "0px" } }}
              />
              {isCurrKPIsSelected ? (
                <Typography
                  as="u"
                  size="17px"
                  m={`0 0 0 10px`}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    selectedKpisVar(
                      omit(
                        selectedKPIs,
                        currentField.KPIs.map(k => k.id)
                      )
                    );
                    selectedFieldsVar(omit(selectedFields, currentField.id));
                  }}
                >
                  {t("wizard.pickKeysForField.resetKPIs", "Reset KPIs")}
                </Typography>
              ) : (
                <Typography
                  as="span"
                  size="17px"
                  m={`0 0 0 10px`}
                  sx={{
                    opacity: ".3",
                  }}
                >
                  {t("wizard.pickKeysForField.selectKPIs", "Select KPIs")}
                </Typography>
              )}
            </Box>
            {currentField.KPIs.map(k => (
              <Accordion key={k.id} m="0 0 10px">
                <AccordionSummary>
                  <label>
                    <Checkbox
                      checked={!!selectedKPIs[k.id]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleKpiSelection(k.id, e.currentTarget.checked);
                      }}
                    />
                  </label>
                  <Typography as="span" size="13px" sx={{ cursor: "pointer" }}>
                    {k.shortName}
                  </Typography>
                </AccordionSummary>
                <AccordionDetail>
                  <Typography size="13px" m="5px 0 0 35px" lh="16px">
                    <div>{k.name}</div>
                    <Box
                      sx={{
                        wordWrap: "pre-line",
                        whiteSpace: "break-spaces",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: k.description,
                      }}
                    />
                  </Typography>
                </AccordionDetail>
              </Accordion>
            ))}
          </>
        )}
      </Box>
      {selectedKpisForCurrentField >= 3 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100%",
            margin: "0 0 -10px",
            padding: "10px 0 0",
            background: "var(--white)",
          }}
        >
          <ExclamationIcon />
          <Typography size="13px">
            {t(
              "wizard.pickKeysForField.kpisFocusRecommended",
              "A focus on 1-2 KPIs is recommended"
            )}
          </Typography>
        </Box>
      )}
      <ButtonsContainer>
        <Button
          onClick={() => {
            navigate(STEP_URLS.weighting);
          }}
        >
          {t("wizard.pickKeysForField.back", "Back")}
        </Button>
        <Button
          disabled={!isKPIsSelected}
          onClick={() => {
            navigate(STEP_URLS.pickFields);
          }}
        >
          {t("wizard.pickKeysForField.next", "Next Field")}
        </Button>
        <Button
          disabled={!isKPIsSelected || oneFieldPerTypeIsNotSelected}
          onClick={() => {
            navigate(STEP_URLS.preview);
          }}
        >
          {t("wizard.pickKeysForField.preview", "Preview")}
        </Button>
        {currentField.locked && <LockedInfo>{lockedFieldText}</LockedInfo>}
      </ButtonsContainer>
      <Guide />
      {oneFieldPerTypeIsNotSelected && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            width: "100%",
            margin: "0 0 -10px",
            padding: "10px 0 0",
            background: "var(--white)",
          }}
        >
          <ExclamationIcon />
          <Typography size="13px">
            {t(
              "wizard.pickKeysForField.oneFieldPerTypeIsNotSelected",
              "One field per type for preview and Scorecard required"
            )}
          </Typography>
        </Box>
      )}
    </StepWrapper>
  );
}

export default PickKeysForField;
