import { forwardRef } from "react";
import styled from "styled-components";
import { Ref } from "../../@types";
import Box, { BoxProps } from "../Box";

type ButtonProps = {
  disabled?: boolean;
} & BoxProps;

const StyledButton = styled(Box)<ButtonProps>`
  min-width: 125px;
  padding: 9px 8px;
  color: var(--button-color);
  text-transform: uppercase;
  white-space: nowrap;
  background: rgb(var(--button-background-rgb));
  border: 0;
  cursor: pointer;
  border: 2px solid rgb(var(--button-background-rgb));

  &:hover {
    background: rgba(var(--button-background-rgb), 0.9);
  }

  ${s =>
    s.disabled
      ? `
        &:disabled {
          background: none;
          color: var(--form-input-disabled-color);
          border-color: var(--form-input-disabled-color);
          cursor: unset ;
        }`
      : ""}
`;
const Button = forwardRef<Ref, ButtonProps>((props, ref) => (
  <StyledButton forwardedAs="button" ref={ref} {...props} />
));

export default Button;
