import { WarningIconProps } from "./types";
import { TAG_ICON_PROPS } from "./utils";

export function WarningIcon({ title }: WarningIconProps) {
  return (
    <svg {...TAG_ICON_PROPS} xmlns="http://www.w3.org/2000/svg">
      <title>{title}</title>
      <g data-name="Group 1275" transform="translate(-1060.887 -500.739)">
        <circle
          data-name="Ellipse 34"
          cx={12}
          cy={12}
          r={12}
          transform="translate(1062 502)"
          fill="var(--icon_tag-warning-background)"
        />
        <path
          data-name="Path 706"
          d="M1073.887 500.739a13.009 13.009 0 0 0-13 13 13.009 13.009 0 0 0 13 13 13 13 0 0 0 13-13 13 13 0 0 0-13-13Zm0 3a10 10 0 0 0-10 10 10 10 0 0 0 10 10 10 10 0 0 0 10-10 10 10 0 0 0-10-10Z"
          fill="var(--icon_tag-warning-color)"
          fillRule="evenodd"
        />
        <path
          data-name="Path 1469"
          d="M1072.295 520.297a.688.688 0 0 0 .114.38l.368.552a.768.768 0 0 0 .572.307h1.325a.768.768 0 0 0 .572-.307l.367-.551a.81.81 0 0 0 .115-.38v-.842h-3.435v.842Zm1.717-9.76a3.772 3.772 0 0 0-3.781 3.762 3.761 3.761 0 0 0 .936 2.488 6.18 6.18 0 0 1 1.12 1.965v.017h3.446v-.017a6.178 6.178 0 0 1 1.125-1.965 3.684 3.684 0 0 0 .936-2.488 3.777 3.777 0 0 0-3.782-3.762Zm2.075 5.588a7.527 7.527 0 0 0-1.054 1.63h-2.036a7.527 7.527 0 0 0-1.054-1.63 2.785 2.785 0 0 1-.681-1.826 2.724 2.724 0 0 1 2.731-2.731 2.753 2.753 0 0 1 2.769 2.731 2.789 2.789 0 0 1-.675 1.826Zm-2.419-3.869a1.721 1.721 0 0 0-1.718 1.719.344.344 0 0 0 .344.344.344.344 0 0 0 .344-.344 1.032 1.032 0 0 1 1.03-1.032.343.343 0 0 0 .344-.344.344.344 0 0 0-.344-.344Z"
          fill="var(--icon_tag-warning-color)"
        />
        <path
          data-name="Path 1464"
          d="M1073.887 506.762v2.611"
          fill="none"
          stroke="var(--icon_tag-warning-color)"
          strokeLinecap="round"
        />
        <path
          data-name="Path 1465"
          d="m1079.637 508.694-1.846 1.847"
          fill="none"
          stroke="var(--icon_tag-warning-color)"
          strokeLinecap="round"
        />
        <path
          data-name="Path 1466"
          d="m1070.227 510.534-1.847-1.846"
          fill="none"
          stroke="var(--icon_tag-warning-color)"
          strokeLinecap="round"
        />
        <path
          data-name="Path 1467"
          d="M1081.667 514.063h-2.611"
          fill="none"
          stroke="var(--icon_tag-warning-color)"
          strokeLinecap="round"
        />
        <path
          data-name="Path 1468"
          d="M1068.96 514.064h-2.611"
          fill="none"
          stroke="var(--icon_tag-warning-color)"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

export function SelectedIcon() {
  return (
    <svg {...TAG_ICON_PROPS}>
      <g transform="translate(-1060.806 -500.739)">
        <circle
          cx="12"
          cy="12"
          r="12"
          transform="translate(1062 502)"
          fill="var(--icon_tag-selected-background)"
        />
        <path
          d="M1757.22-348.505a13.009,13.009,0,0,0-13,13,13.009,13.009,0,0,0,13,13,13,13,0,0,0,13-13A13,13,0,0,0,1757.22-348.505Zm0,3a10,10,0,0,0-10,10,10,10,0,0,0,10,10,10,10,0,0,0,10-10A10,10,0,0,0,1757.22-345.505Z"
          transform="translate(-683.414 849.244)"
          fill="var(--icon_tag-selected-color)"
          fillRule="evenodd"
        />
        <path
          d="M1755.22-330.3l-5-5,1.41-1.41,3.59,3.58,7.59-7.59,1.41,1.42Z"
          transform="translate(-683.414 849.244)"
          fill="var(--icon_tag-selected-color)"
        />
      </g>
    </svg>
  );
}

export function LockIcon() {
  return (
    <svg {...TAG_ICON_PROPS}>
      <defs>
        <clipPath id="clip-path">
          <rect width="26" height="26" fill="none" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)">
        <path
          d="M13.113,1.261a12,12,0,1,1-12,12,12,12,0,0,1,12-12"
          fill="#fff"
        />
        <path
          d="M17.394,11.164v-2.1A3.669,3.669,0,0,0,13.773,5.36H12.185A3.669,3.669,0,0,0,8.564,9.068v2.1a1.46,1.46,0,0,0-1.208,1.422v6.6A1.467,1.467,0,0,0,8.834,20.64h8.331a1.468,1.468,0,0,0,1.479-1.446v-6.6a1.461,1.461,0,0,0-1.25-1.429M13.687,16.1v1.649a.5.5,0,0,1-.5.489h-.125a.5.5,0,0,1-.5-.489V16.123a1.458,1.458,0,0,1-.958-1.361,1.49,1.49,0,0,1,2.979,0,1.456,1.456,0,0,1-.9,1.336m2.5-4.952H9.774V9.068a2.452,2.452,0,0,1,2.411-2.486h1.588a2.452,2.452,0,0,1,2.411,2.486Z"
          fill="#404040"
        />
        <path
          d="M13,0A13,13,0,1,0,26,13,13.009,13.009,0,0,0,13,0m0,3A10,10,0,1,0,23,13,10,10,0,0,0,13,3"
          fill="#404040"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
